import React, { useState, useEffect, useCallback, useRef } from "react"
import { Link } from "gatsby"

//assets
import withText from "../assets/images/servbees-logo.svg"
import Close from "../assets/images/icon-close.svg"
// import contactIcon from "../assets/images/header/contact icon.svg"
// import downloadIcon from "../assets/images/header/download icon.svg"
import arrowRight from "../assets/images/side-menu/arrow-right.svg"
import post from "../assets/images/header/menu-icon-post.svg"
import service from "../assets/images/header/menu-icon-service.svg"
import need from "../assets/images/header/menu-icon-need.svg"

const Header = ({
  keyword,
  handleSearch,
  handleSearchShow,
  showSearchBar,
  handleClear,
}) => {
  const [activeClass, setActiveClass] = useState(false)
  const [isHeaderShown, setIsHeaderShown] = useState(true)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [showBurgerMenu, setShowBurgerMenu] = useState(false)
  const [showHowTo, setShowHowTo] = useState(false)

  const headerAnimate = useCallback(() => {
    var st = window.pageYOffset || document.documentElement.scrollTop
    if (st > lastScrollTop) {
      // if (window.pageYOffset > navbarHeight) {
      //   if (isHeaderShown) {
      //     setIsHeaderShown(false)
      //   }
      // } else {
      //   setIsHeaderShown(true)
      // }

      setIsHeaderShown(true)
    } else {
      setIsHeaderShown(true)
    }
    setLastScrollTop(st)
  }, [lastScrollTop])

  const scrollHandler = () => {
    if (window.scrollY > 51) {
      setActiveClass(true)
    } else {
      setActiveClass(false)
    }
  }

  let isScrolling = useRef(false)
  const scrolling = useCallback(() => {
    window.clearTimeout(isScrolling.current)
    isScrolling.current = setTimeout(() => {
      setIsHeaderShown(true)
    }, 1000)
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", headerAnimate)
    window.addEventListener("scroll", scrollHandler)
    window.addEventListener("scroll", scrolling)

    if (window.location.href.includes("#")) {
      if (window.scrollY > 51) {
        setActiveClass(true)
      } else {
        setActiveClass(false)
      }
    }

    return () => {
      window.removeEventListener("scroll", headerAnimate)
      window.removeEventListener("scroll", scrollHandler)
    }
  }, [headerAnimate, scrolling])

  const renderSearch = () => {
    const pathName = typeof window !== "undefined" && window.location.pathname

    switch (pathName) {
      case "/terms":
      case "/privacy":
        return <></>
      default:
        return (
          typeof window !== "undefined" &&
          !window.location.pathname.includes("faq") && (
            <div
              className={`search-animate ${
                showSearchBar ? "show-search-bar" : ""
              }`}
            >
              <input
                type="search"
                className={`${showSearchBar ? "input-focus" : ""}`}
                value={keyword}
                onClick={handleSearchShow}
                onChange={handleSearch}
                placeholder="Search..."
              ></input>
              <img
                src={Close}
                alt="close"
                className={`close-icon ${showSearchBar ? "open" : ""}`}
                onClick={handleClear}
                role="presentation"
              />
            </div>
          )
        )
    }
  }

  return (
    <header
      className={`header ${activeClass ? "header-scroll" : ""} ${
        isHeaderShown ? "header-animate-show" : "header-animate-hide"
      }`}
    >
      <div className="container">
        <div className="header-holder">
          <Link
            to="/"
            className={`logo ${showSearchBar ? "hide-search-show" : ""}`}
          >
            <img
              src={withText}
              className="header-logo header-title"
              alt="header logo"
            />
          </Link>
          <div className="header-content-holder">
            <div className={`menu-list ${showBurgerMenu ? "show-menu" : ""}`}>
              <div className="menu-list-item">
                <Link to="/" className="logo-mobile">
                  <img
                    src={withText}
                    className="header-logo header-title-mobile"
                    alt="header logo"
                  />
                </Link>
              </div>
              <div
                className="menu-list-item"
                // onMouseEnter={() => setShowHowTo(true)}
              >
                <Link to="/how-to" className="menu-link">
                  How To's
                  {/* <img src={angleDown} className="angle-down" alt="" /> */}
                  <img src={arrowRight} className="arrow-right" alt="" />
                </Link>
                {showHowTo ? (
                  <div
                    aria-hidden
                    className="how-to-menu"
                    onMouseEnter={() => setShowHowTo(true)}
                    onMouseLeave={() => setShowHowTo(false)}
                  >
                    <Link to="/how-to#Sell" className="how-to-menu-item">
                      <img src={post} className="menu-icon" alt="" />
                      Create Post
                    </Link>
                    <Link to="/how-to#Service" className="how-to-menu-item">
                      <img src={service} className="menu-icon" alt="" />
                      Be an Affiliate
                    </Link>
                    <Link to="/how-to#Need" className="how-to-menu-item">
                      <img src={need} className="menu-icon" alt="" />
                      Get Paid as an Affiliate
                    </Link>
                  </div>
                ) : null}
              </div>
              <div className="menu-list-item">
                <Link to="/community-standards" className="menu-link">
                  Community Standards
                  <img src={arrowRight} className="arrow-right" alt="" />
                </Link>
              </div>
              <div className="menu-list-item">
                <Link to="/beebo-rider" className="menu-link">
                  Beebo Rider
                  <img src={arrowRight} className="arrow-right" alt="" />
                </Link>
              </div>
              <div className="menu-list-item">
                <Link to="/faq" className="menu-link">
                  FAQs
                  <img src={arrowRight} className="arrow-right" alt="" />
                </Link>
              </div>
              <div className="menu-list-item">
                <Link to="/about" className="menu-link">
                  About Us
                  <img src={arrowRight} className="arrow-right" alt="" />
                </Link>
              </div>
              <div className="menu-list-item">
                <div className="btn-holder">
                  <Link to="/contact" className="menu-link">
                    <button className="btn btn-contact">Contact Us</button>
                  </Link>
                  <Link to="/download" className="menu-link">
                    <button className="btn btn-download">Download App</button>
                  </Link>
                </div>
              </div>
            </div>
            <button
              className={`hamburger ${showSearchBar ? "hide-search-show" : ""}${
                showBurgerMenu ? "show-hamburger-menu" : ""
              }`}
              type="button"
              onClick={() =>
                setShowBurgerMenu(showBurgerMenu => !showBurgerMenu)
              }
            >
              <span className="hamburger-inner"></span>
            </button>
            {renderSearch()}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
